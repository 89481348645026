/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';

import LayoutMain from 'layouts/layout-main';
import DownloadPDF from 'components/pages/download-center/download-pdf';
import Pagination from 'components/pages/blog/pagination';

const DownloadCenter = ({ data: { wpPage: data, posts }, pageContext, location }) => (
  <LayoutMain
    seo={{ ...data.seo, date: pageContext.date }}
    footerTheme="with-border"
    withBanner={pageContext.showBanner}
    withBottomOffset
    pageContext={pageContext}
    location={location}
  >
    <DownloadPDF
      title={data.title}
      items={posts.nodes}
      categories={pageContext.categories}
      pageRootPath={data.uri}
      activeCategoryId={pageContext.categoryId}
    />
    {pageContext.pageCount > 1 && (
      <Pagination
        pageCount={pageContext.pageCount}
        currentPageIndex={pageContext.currentPageIndex}
        pageRootPath={data.uri}
        categoryPath={pageContext.categoryPath}
      />
    )}
  </LayoutMain>
);

export default DownloadCenter;

export const query = graphql`
  query ($id: String!, $categoryId: String, $skip: Int!, $limit: Int!) {
    wpPage(id: { eq: $id }) {
      uri
      title
      ...wpPageSeo
    }
    posts: allWpPdfResource(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $categoryId } } } } }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        uri
        categories {
          nodes {
            name
          }
        }

        acf {
          previewDescription
        }
      }
    }
  }
`;
