import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';
import Categories from 'components/shared/categories';
import Button from 'components/shared/button';
import Link from 'components/shared/link';

import styles from './download-pdf.module.scss';

const cx = classNames.bind(styles);

const DownloadPDF = ({
  title,
  items,
  categories,
  pageRootPath,
  activeCategoryId,
  labelMobileCategoryAll,
}) => (
  <section className={cx('wrapper')}>
    <div className="container">
      <Heading className={cx('title')} size="xl">
        {title}
      </Heading>
      {!!categories.length && (
        <Categories
          className={cx('categories')}
          categories={categories}
          pageRootPath={pageRootPath}
          activeCategoryId={activeCategoryId}
          labelMobileCategoryAll={labelMobileCategoryAll}
        />
      )}
      <div className={cx('items')}>
        {items.map(({ title, uri, acf: { previewDescription } }, index) => (
          <div className={cx('item')} key={index}>
            <div className={cx('content')}>
              <div className={cx('left')}>
                <Link className={cx('item-title-wrapper')} to={uri}>
                  <Heading className={cx('item-title')} tag="h3" size="lg">
                    {title}
                  </Heading>
                </Link>
                {previewDescription && (
                  <p
                    className={cx('item-description')}
                    dangerouslySetInnerHTML={{ __html: previewDescription }}
                  />
                )}
              </div>
              <Button className={cx('item-button')} to={uri} theme="accent-primary" size="sm">
                download
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

DownloadPDF.defaultProps = {
  activeCategoryId: '',
  labelMobileCategoryAll: 'All Resources',
};

DownloadPDF.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      categories: PropTypes.objectOf(PropTypes.any).isRequired,
    })
  ).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageRootPath: PropTypes.string.isRequired,
  activeCategoryId: PropTypes.string,
  labelMobileCategoryAll: PropTypes.string,
};

export default DownloadPDF;
